<template>
  <div class="Login">
    <div class="tit_img">
      <img src="../../assets/images/login/biao.png" alt="" />
    </div>
    <el-card class="box_card">
      <el-row class="flex-col-center">
        <el-col :span="12">
          <p class="tit">在线注册报名</p>
        </el-col>
        <el-col :span="12">
          <p class="text-right">
            已有账号?<a @click="link('MLogin')">点击登录</a>
          </p>
        </el-col>
      </el-row>
      <div class="form">
        <el-form
          ref="ruleForm"
          :model="form"
          :rules="rules"
          label-width="80px"
          label-position="top"
        >
          <el-form-item label="姓名:" prop="name">
            <el-input v-model="form.name" placeholder="请输入姓名"></el-input>
          </el-form-item>
          <el-form-item label="手机号:" prop="mobile">
            <el-input
              v-model="form.mobile"
              placeholder="请输入手机号"
            ></el-input>
          </el-form-item>
          <el-form-item label="短信验证码:" prop="codes">
            <el-input
              v-model="form.codes"
              placeholder="请输入短信验证码"
            ></el-input>
          </el-form-item>
          <el-form-item>
            <el-button @click="getCode" :disabled="show">{{
              sendCon
            }}</el-button>
          </el-form-item>
          <el-form-item label="密码:" prop="password">
            <el-input
              v-model="form.password"
              placeholder="请输入密码"
              type="password"
            ></el-input>
          </el-form-item>
          <el-form-item label="确认密码:" prop="rpassword">
            <el-input
              v-model="form.rpassword"
              placeholder="请确认密码"
              type="password"
            ></el-input>
          </el-form-item>
          <el-form-item label="推荐人编号:">
            <el-input
              v-model="form.agent"
              placeholder=""
              :readonly="readonly"
            ></el-input>
          </el-form-item>
          <el-form-item prop="agree">
            <p class="agr">
              <el-checkbox v-model="form.agree"
                >我同意并遵守<a @click="toagree"
                  >《网培师平台用户协议》</a
                ></el-checkbox
              >
            </p>
          </el-form-item>
          <el-form-item class="btn">
            <el-button type="primary" @click="register('ruleForm')"
              >注册</el-button
            >
          </el-form-item>
        </el-form>
      </div>
    </el-card>
  </div>
</template>
<script>
let _this
export default {
  data() {
    var validateMobile = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入手机号'))
      } else {
        if (/^1[3456789]\d{9}$/.test(value)) {
          callback()
        } else {
          callback(new Error('请输入正确的手机号'))
        }
      }
    }
    var validatePass = (rule, value, callback) => {
      if (value == '') {
        callback(new Error('请输入密码'))
      } else if (value.length < 8) {
        callback(new Error('输入密码不少于8位'))
      } else {
        if (this.form.rpassword !== '') {
          this.$refs.ruleForm.validateField('rpassword')
        }
        callback()
      }
    }
    var validatePass2 = (rule, value, callback) => {
      if (value == '') {
        callback(new Error('请再次输入密码'))
      } else if (value !== this.form.password) {
        callback(new Error('两次输入密码不一致!'))
      } else {
        callback()
      }
    }
    var validateCode = (rule, value, callback) => {
      if (value == '') {
        callback(new Error('请输入验证码'))
      } else if (value.length !== 6) {
        callback(new Error('验证码输入有误!'))
      } else {
        callback()
      }
    }
    var validateagree = (rule, value, callback) => {
      if (value) {
        callback()
      } else {
        callback(new Error('您未同意网培师平台用户协议'))
      }
    }
    return {
      show: false,
      count: '',
      timer: null,
      sendCon: '获取验证码',

      form: {
        name: '',
        mobile: '',
        codes: '',
        agree: '',
        password: '',
        rpassword: '',
        agent: '',
      },
      form1: {},
      first: true,
      res: [
        '个人能力提升',
        '职业背景提升',
        '应企业要求',
        '兴趣爱好',
        '打算进入网培行业',
        '其它',
      ],
      methods: ['音视频直播', '音视频录播', '一对一远程辅导', '其它'],
      rules: {
        mobile: {
          required: true,
          validator: validateMobile,
          message: '请输入手机号',
          trigger: ['blur', 'change'],
        },
        name: {
          required: true,
          message: '请输入本人真实姓名',
          trigger: ['blur', 'change'],
        },
        password: {
          required: true,
          validator: validatePass,
          trigger: 'blur',
        },
        rpassword: {
          required: true,
          validator: validatePass2,
          trigger: 'blur',
        },
        codes: {
          required: true,
          validator: validateCode,
          trigger: 'blur',
        },
        agree: {
          required: true,
          validator: validateagree,
          message: '您未同意网培师平台用户协议',
          trigger: 'change',
        },
      },
      loading: false,
      readonly: false,
    }
  },
  created() {
    try {
      _this = this

      this.init()
      if (_this.$route.params.agent) {
        document.cookie = 'agent=' + escape(_this.$route.params.agent.trim())
        _this.form.agent = this.getcookie()
        _this.readonly = 'readonly'
      } else if (this.getcookie()) {
        _this.form.agent = this.getcookie()
        _this.readonly = 'readonly'
      }
    } catch (err) {
      console.log(err)
    }
  },
  methods: {
    init() {},
    link(name) {
      this.$router.push({ name: name })
    },
    register(formName) {
      this.$refs[formName].validate(valid => {
        if (valid) {
          _this.$http
            .post(process.env.VUE_APP_URL + 'register', this.form)
            .then(data => {
              if (data.data.code == 200) {
                // _this.$message.success('注册成功')
                _this.$http
                  .post(process.env.VUE_APP_URL + 'login', {
                    mobile: _this.form.mobile,
                    password: _this.form.password,
                  })
                  .then(data => {
                    _this.loading = true
                    if (data.data.code == 200) {
                      _this.loading = false
                      // _this.$message.success('登录成功')
                      _this.Storage.setStorage('token', data.data.token)
                      _this.$store.commit('setUser', data.data.data)
                      // _this.go_url()
                      // _this.link('Home')
                      if (_this.readonly == false) {
                        _this.$router.push({ path: '/' })
                      } else {
                        _this.$router.push({
                          name: 'MTraincourse',
                          params: { isfirst: 'first' },
                        })
                      }
                    } else {
                      _this.$message.error(data.data.msg)
                      _this.loading = false
                    }
                  })
                  .catch(err => {
                    _this.$message.error('网络错误，请重试')
                    console.log(err)
                    _this.loading = false
                  })
              } else {
                _this.$message.error(data.data.msg)
                _this.loading = false
              }
            })
            .catch(err => {
              _this.$message.error('网络错误，请重试')
              console.log(err)
              _this.loading = false
            })
        } else {
          return false
        }
      })
    },
    getCode() {
      let mobile = this.form.mobile
      let params = {
        type: 'reg',
        mobile: this.form.mobile,
      }
      if (/^1[3456789]\d{9}$/.test(mobile)) {
        _this.$http
          .post(process.env.VUE_APP_URL + 'sendCode', params)
          .then(data => {
            if (data.data.code == 200) {
              _this.countdown()
              _this.$message.success('验证码已发送')
            } else {
              _this.$message.error(data.data.msg)
            }
          })
          .catch(err => {
            _this.$message.error('网络错误，请重试')
            console.log(err)
          })
      } else {
        this.$message.warning('请输入正确格式的手机号')
        return
      }
    },
    countdown() {
      const TIME_COUNT = 60
      if (!this.timer) {
        this.count = TIME_COUNT
        this.timer = setInterval(() => {
          if (this.count > 0 && this.count <= TIME_COUNT) {
            this.show = true
            this.count--
            this.sendCon = this.count + '秒后再次获取'
          } else {
            this.show = false
            clearInterval(this.timer)
            this.timer = null
            this.sendCon = '获取验证码'
          }
        }, 1000)
      }
    },
    toagree() {
      this.$router.push({ path: '/agreement' })
    },
    getcookie() {
      var arr = document.cookie.split(';')
      for (var i = 0; i < arr.length; i++) {
        var arrcookie = arr[i].split('=')
        if (arrcookie[0].trim() == 'agent') {
          return arrcookie[1]
        }
      }
      return false
    },
  },
}
</script>
<style lang="less" scoped>
.Login {
  width: 100%;
  height: 100%;
  background: url('../../assets/images/login/bg.png');
  background-repeat: no-repeat;
  background-size: cover;
  padding: 10% 0px;
  .tit_img {
    margin: 20px auto;
    width: 60vw;
    img {
      width: 100%;
    }
  }
  h1 {
    font-size: 60px;
    color: #ffffff;
    text-align: center;
    margin: 0px 0px 15%;
  }
  .box_card {
    width: 80vw;
    margin: 0px auto;
    p {
      font-size: 10px;
      a {
        color: #6f8bff;
      }
    }
    .tit {
      font-weight: 800;
      font-size: 18px;
    }
  }
  .form {
    padding: 20px 20px 0px;
    /deep/.el-form-item__label {
      line-height: 20px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #333333;
      font-size: 14px;
    }
    .el-form-item {
      margin-bottom: 15px !important;
    }
    .btn {
      text-align: center;
      .el-button {
        width: 153px;
        height: 40px;
        background: #960f23;
        border-radius: 4px;
        border: none;
      }
    }
    /deep/.el-form-item__error {
      position: relative;
    }
    /deep/.el-checkbox__label {
      font-size: 12px;
    }
  }
}
</style>
